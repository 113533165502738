import React, { Component }  from 'react'; 
import Apiconnect from "../../services/Apiconnect.js";
import { CSVLink } from "react-csv";
import { withAlert } from 'react-alert';
import '../pages/uploadfile.css';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Loadder from "./Loadder";
import Modal from 'react-modal';


class Employee_bulk_upload extends Component { 
        constructor(props) {
            super(props);
            this.state = { 
                loader:false,selectedFile:'',selectedSalaryFile:'',selectedLeaveFile:'',selectedAttendanceFile:'',dwnEmpSalaryfile:'',company_id: Apiconnect.get_company_id(),
            
            };
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleSalaryChange =  this.handleSalaryChange.bind(this);
            this.handleLeaveChange =  this.handleLeaveChange.bind(this);
            this.handleAttendanceChange =  this.handleAttendanceChange.bind(this);
            // this.salaryfile =  this.salaryfile.bind(this);
        }

        componentDidMount() {
            this.salaryfile();
        }

        salaryfile(){
            console.log('test');
           // Axios.get(`http://localhost:3050/api/employee/empsalaryfile/${this.state.company_id}`).then((response) => {
            Apiconnect.getData(`employee/empsalaryfile/${this.state.company_id}`).then((response) => {

               let _xtract = Apiconnect.decrypt_obj(response.data.data);
                this.setState({dwnEmpSalaryfile: _xtract.reverse()});
                 
               
            });
         }

        handleInputChange(event) {
            this.setState({
                selectedFile: event.target.files[0],
              })
        }
         
        submit = async e => {
            const data = new FormData() ;
            data.append('csvfile', this.state.selectedFile);   
            console.log(data);
          //  Axios.post(`http://localhost:3050/api/tush/uploadcsv2/${this.state.company_id}`, data).then((response) => {

             Apiconnect.postData(`upload/employee_bulk_data/${this.state.company_id}`, data).then((response) => {           
                this.props.alert.success(response.data.message)
                console.log(response.data.message);
            });
             
        };


          handleLeaveChange(event) {
           
            this.setState({
                selectedLeaveFile: event.target.files[0],
              })
              console.log(event.target.files[0]);
             
        }
   
         
        submitLeave = async e => {
            const data = new FormData() ;
            console.log(this.state.selectedLeaveFile);
            data.append('csvleavefile', this.state.selectedLeaveFile); 
            console.log(data);  
            //Axios.post(`http://localhost:3050/api/tush/uploadleavecsv/${this.state.company_id}`, data).then((response) => {

            Apiconnect.postData(`upload/uploadleavecsv/${this.state.company_id}`, data).then((response) => {           
                this.props.alert.success(response.data.message)
                console.log(response.data.message);
            });
             
        };


        handleAttendanceChange(event) {
           
            this.setState({
                selectedAttendanceFile: event.target.files[0],
              })
              console.log(event.target.files[0]);
             
        }
   
         
        submitAttendance = async e => {

            this.setState({loader:true });
            const data = new FormData() ;
            console.log(this.state.selectedAttendanceFile);
            data.append('csvattendancefile', this.state.selectedAttendanceFile); 
            console.log(data);  
           // Axios.post(`http://localhost:3050/api/upload/uploadattendacecsv/${this.state.company_id}`, data).then((response) => {

            Apiconnect.postData(`upload/uploadattendacecsv/${this.state.company_id}`, data).then((response) => {           
                this.props.alert.success(response.data.message)
                console.log(response.data.message);
                this.setState({loader:false });
            });
             
        };

         sample_Attendance_file = () =>{
            console.log('t');
        }



        handleSalaryChange(event) {
           
            this.setState({
                selectedSalaryFile: event.target.files[0],
              })
             
        }
   
         
        submitSalary = async e => {
            const data = new FormData() ;
            console.log(this.state.selectedSalaryFile);
            data.append('csvsalaryfile', this.state.selectedSalaryFile);   
           // Axios.post(`http://localhost:3050/api/test/uploadsalarycsv/${this.state.company_id}`, data).then((response) => {

            Apiconnect.postData(`upload/uploadsalarycsv/${this.state.company_id}`, data).then((response) => {           
                this.props.alert.success(response.data.message)
                console.log(response.data.message);
            });
             
        };

       

        // emp_sample_file = ['Bruce', 'Clark', 'Diana'];

        render() {

            const customStyles1 = {
                content: {
                   top: '55%', width: '60%',
                   left: '50%',
                   right: 'auto',
                   bottom: 'auto',
                   marginRight: '-50%',
                   transform: 'translate(-50%, -50%)',
                   border:'unset ',
                   backgroundColor:'unset ',
                },
             };
             console.log(this.state.loader);
       

    return (

        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">

                      {/* ////////////------------>Lodder<------------////////////////// */}
                        <div >
                            <Modal isOpen={this.state.loader} style={customStyles1}>
                                            {<Loadder/>}
                            </Modal>
                        </div>
                    {/* ////////////------------>Lodder<------------////////////////// */}

                    {/* <div className="col-sm-12"> */}
                    <div className="col-sm-6">
                    <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Employee Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">

                                            <div class="dropzone">
                                                <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                {/* <input type="file" class="upload-input" value="" /> */}
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="csvfile" onChange={this.handleInputChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-lg" onClick={()=>this.submit()}>Submit</button>
                                            <br/>
                                            <CSVLink filename={"employee_sample.csv"} style={{ marginTop: '13px', float: 'right',paddingRight:'10px' }} data="emp_code,reporting_officer,department,employement_type,first_name,middle_name,last_name,gender,date_of_birth,email,phone,phone_secondary,email_secondary,is_active,present_address_one,present_address_two,present_state_id,present_city_id,present_area,present_zip,present_landmark,parmanent_address_one,parmanent_address_two,parmanent_state_id,parmanent_city_id,parmanent_area,parmanent_zip,parmanent_landmark,blood_group,religion,caste,nationality,marital_status,anniversary_date,emergency_contact_person,emergency_relationship_person_id,emergency_contact_number,emergency_contact_person2,emergency_relationship_person_id2,emergency_contact_number2,pan,passport_no,aadhaar,payment_mode,bank_name,ifsc_code,account_number,is_epf,UAN_number,epf_number,pf_start_date,is_esi,esi_number,dispencery,confirmation_date,joining_date,resign_date,leaving_date,promotion_date,transfer_date,pm_userId,emp_shift,salary_template,ctc_yearly,password" >
                                            <u><i class="fa fa-download"></i> Employee smaple file</u>
                                            </CSVLink>

                                        </div>
                                        
                                    </div>
                                </div>
                              
                        </div>
                        </div>
                        <div className="col-sm-6">
                        <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Salary Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">

                                            <div class="dropzone">
                                                <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="csvsalaryfile" onChange={this.handleSalaryChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-lg" onClick={()=>this.submitSalary()}>Submit</button>

                                            <br/>
                                            <CSVLink filename={"salary_sample.csv"} style={{ marginTop: '13px', float: 'right',paddingRight:'10px' }} data={this.state.dwnEmpSalaryfile} >
                                            <u><i class="fa fa-download"></i> Salary smaple file</u>
                                            </CSVLink>

                                        </div>
                                    </div>
                                </div>
                              
                        </div>
                        </div>
                       
                    </div>
                    <div className="row">
                    <div className="col-sm-6">
                        <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Manual Leave Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">
                                            
                                        <div class="dropzone">
                                            <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                {/* <input type="file" class="upload-input" value="" /> */}
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="csvleavefile" onChange={this.handleLeaveChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-lg" onClick={()=>this.submitLeave()}>Submit</button>
                                            <br/>
                                            <CSVLink filename={"leave_sample.csv"} style={{ marginTop: '13px', float: 'right',paddingRight:'10px' }} data="emp_id,emp_name,leave_type,leave_count,reason" >
                                            <u><i class="fa fa-download"></i> leave smaple file</u>
                                            </CSVLink>
                                        </div>
                                    </div>
                                </div>
                              
                        </div>
                        </div>  
                        <div className="col-sm-6">
                        <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Attendance Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">
                                            
                                        <div class="dropzone">
                                            <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                {/* <input type="file" class="upload-input" value="" /> */}
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="csvattendancefile" onChange={this.handleAttendanceChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-lg" onClick={()=>this.submitAttendance()}>Submit</button>
                                            <a target='_blank' href={process.env.React_App_BASE_URL_API+'/uploads/attendance_sample.csv'}  style={{ marginTop: '13px', float: 'right',paddingRight:'10px' }} class=""><i class="fa fa-download"></i> Attendance smaple file</a>
                                        </div>
                                    </div>
                                </div>
                              
                        </div>
                        </div> 


                    </div>
                {/* </div> */}



            </div>
        </div>
    )
        }
}

export default withAlert()(Employee_bulk_upload)